import {
  Accordion,
  Button,
  ButtonRow,
  Card,
  UserValueProps,
} from "@wisr/react-ui";
import { Link } from "gatsby";
import React from "react";
import image from "../../assets/images/credit-score-orange.svg";
import { CTAWidgetComponent } from "../../widgets/shared/cta/cta-widget.component";
import { NavLink } from "../../widgets/shared/nav-link/nav-link.component";
import { WidgetWrapper } from "../../widgets/widget.component";
import benefitsStyle from "./benefits-credit.scss";

export const BenefitsCreditComponent = () => {
  return (
    <WidgetWrapper>
      <style jsx>{benefitsStyle}</style>
      <div className="prefix">
        <CTAWidgetComponent type="credit">
          <div className="full-background-credit">
            <div className="title">
              <h2>Check your credit scores, without affecting them</h2>
            </div>
            <div className="description">
              <p className="text-body">
                A credit score is a number that sums up your past money
                behaviour. Credit scores exist to give lenders a sense of how
                responsible you are. They need to go off something right?
              </p>
            </div>
            <div className="cta">
              <div className="grid">
                <div className="half">
                  <ButtonRow>
                    <Button button="primary" theme="white">
                      <Link to="/credit/create-profile/">Get scores</Link>
                    </Button>
                  </ButtonRow>
                  <p className="text-body">
                    It won&apos;t impact your credit score
                  </p>
                </div>
                <div className="half">
                  <img src={image} />
                </div>
              </div>
            </div>
          </div>
        </CTAWidgetComponent>
        <Card>
          <div className="credit">
            <div className="title">
              <h3>Credit yourself for checking your credit.</h3>
            </div>
            <div className="content">
              <UserValueProps
                align="left"
                values={[
                  {
                    type: "tick",
                    value: (
                      <>
                        <h3>Improve your credit score</h3>
                        <p className="text-body">
                          Be notified when your score changes.
                        </p>
                      </>
                    ),
                  },
                  {
                    type: "tick",
                    value: (
                      <>
                        <h3>Understand your credit position</h3>
                        <p className="text-body">You see what lenders see.</p>
                      </>
                    ),
                  },
                  {
                    type: "tick",
                    value: (
                      <>
                        <h3>See what impacts your score</h3>
                        <p className="text-body">
                          Find out how your daily money decisions affect your
                          scores.
                        </p>
                      </>
                    ),
                  },
                  {
                    type: "tick",
                    value: (
                      <>
                        <h3>Look for credit errors</h3>
                        <p className="text-body">
                          Catch mistakes early so you stay alert to potential
                          fraud.
                        </p>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Card>
      </div>
      <div className="suffix">
        <Card>
          <div className="credit">
            <div className="subtitle">
              <p>Smart info on credit scores</p>
            </div>
            <div className="title">
              <h3>For the part of you that has questions.</h3>
            </div>
            <div className="content">
              <Accordion title="Will using this tool impact my credit score?">
                <p>
                  Not at all! We make a &apos;soft enquiry&apos; known as Access
                  Seeker with participating credit reporting bureaus (CRB). It
                  will touch your credit file, but it does not impact your
                  credit score, and other Financial Institutions will not be
                  able to see this &apos;soft enquiry&apos;.
                </p>
              </Accordion>
              <Accordion title="What can I do if I find an error?">
                <p>
                  Any incorrect information can be reported to the credit bureau
                  itself which you can easily do from <a href="#">this page</a>.
                </p>
                {/* Link is TBC */}
                <p>They have 30 days to respond.</p>
              </Accordion>
              <Accordion title="What do I do with my credit scores?">
                <p>
                  Your scores give you a view of your financial fitness, to
                  perhaps get a more competitive rate with your lenders. Just
                  note though, lenders will take into account other factors when
                  assessing your creditworthiness. It&apos;s always good to
                  check with your financial institution before applying for
                  credit.
                </p>
              </Accordion>
            </div>
            <div className="cta">
              <NavLink
                to="https://wisr.com.au/help-categories/credit-scores"
                external
              >
                Go to FAQ
              </NavLink>
            </div>
          </div>
        </Card>
      </div>
    </WidgetWrapper>
  );
};
